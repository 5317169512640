import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Breadcrumb from "../components/breadcrumb"
import EventDetail from "../components/event-detail"
import Layout from "./default"
import MDXProvider from "../components/mdx-provider"
import Seo from "../components/seo"
import Stack from "../components/stack"

const LayoutEvent = ({ data: { mdx } }) => {
  const { titel, backdrop } = mdx.frontmatter

  return (
    <Layout backdrop={backdrop || "aussen"}>
      <Seo title={titel || null} description={mdx.excerpt} />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Veranstaltungen",
              link: "/veranstaltungen",
            },
            {
              title: titel,
              link: `/veranstaltungen${mdx.fields.slug}`,
            },
          ]}
        />
        <EventDetail event={mdx}>
          <MDXProvider>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </MDXProvider>
        </EventDetail>
      </Stack>
    </Layout>
  )
}

export const pageQuery = graphql`
  query EventDetailQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      excerpt(pruneLength: 150)
      fields {
        slug
      }
      frontmatter {
        reihe
        titel
        untertitel
        start
        ende
        eintritt
        ort
      }
    }
  }
`

export default LayoutEvent
